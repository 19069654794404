<template>
  <svg :width="iconWidth" :height="iconHeight" :style="{ 'margin-top': iconTopMargin }">
    <!-- El resto del contenido SVG -->
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 128 128"
      :width="iconWidth"
      :height="iconHeight"
    >
      <path
        fill="#fff"
        d="M73.8,41.2c10.2,0.9,20.3,3.4,30,7.2l5.2-13l0,0c-28.9-11.6-61.1-11.6-90,0l0,0l5.2,13 c9.7-3.9,19.8-6.3,30-7.2c0,0,9.8,59.5,9.8,59.5S73.8,41.2,73.8,41.2z"
      />
      <path
        fill="#444b54"
        d="M110.1,32.7c-29.7-11.9-62.5-11.9-92.2,0c-1.5,0.6-2.3,2.4-1.7,3.9c0.6,1.5,2.4,2.3,3.9,1.7 c28.3-11.3,59.5-11.3,87.8,0c0.4,0.1,0.7,0.2,1.1,0.2c1.2,0,2.3-0.7,2.8-1.9C112.4,35,111.7,33.3,110.1,32.7z"
      />
      <path
        fill="#444b54"
        d="M104.9,45.7c-9.9-4-20.3-6.5-30.9-7.5c-1.6-0.1-2.9,0.9-3.2,2.4c0,0,0,0,0,0.1L64,82.2l-6.8-41.5 c0,0,0,0,0-0.1c-0.3-1.5-1.7-2.6-3.2-2.4c-10.6,1-20.9,3.5-30.9,7.5c-0.8,0.3-1.5,1-1.7,1.9c-0.3,0.9-0.2,1.8,0.3,2.5l5.3,8.3 c0.6,0.9,1.5,1.4,2.5,1.4c0.6,0,1.1-0.2,1.6-0.5c1.4-0.9,1.8-2.7,0.9-4.1l-3.3-5.2c7.5-2.7,15.2-4.5,23-5.5l9.3,56.7 c0.2,1.5,1.5,2.5,3,2.5c0.2,0,0.3,0,0.5,0c1.3-0.2,2.2-1.2,2.5-2.4c0,0,0,0,0,0l9.3-56.7c7.8,0.9,15.5,2.8,23,5.5l-3.3,5.2 c-0.9,1.4-0.5,3.3,0.9,4.1c0.5,0.3,1.1,0.5,1.6,0.5c1,0,2-0.5,2.5-1.4l5.3-8.3c0.5-0.8,0.6-1.7,0.3-2.5 C106.4,46.7,105.7,46,104.9,45.7z"
      />
    </svg>
  </svg>
</template>

<script>
export default {
  name: 'TeslaIcon',
  props: {
    iconWidth: { type: String, default: '20' },
    iconHeight: { type: String, default: '20' },
    iconTopMargin: { type: String, default: '2px' },
  },
}
</script>
