/**
 * Mixin para obtener iconos y colores para usar en los traslados y reservas.
 * @mixin
 */
export default {
  methods: {
    /**
     * @description Obtiene el color del icono de traslado segun sea el caso (sencillo o redondo)
     * @param {object} item elemento de la tabla
     */
    getColorIconoTraslado(item) {
      return item.servicios[0].tipo_traslado === 'redondo' ? 'blue' : 'blue-grey lighten-5'
    },

    /**
     * Determina el color del ícono basado en el tipo de vehículo.
     * @param {Object} item - El objeto que contiene los detalles del vehículo.
     * @return {string} La clase de color que se aplicará al ícono.
     */
    getColorIconoVehiculo(item) {
      const tipo = item
      switch (tipo) {
        case 'tesla':
          return 'primary'
        case 'luxe':
          return 'primary'
        case 'sedan':
          return 'accent'
        default:
          return 'blue-grey lighten-5'
      }
    },

    /**
     * @description Obtiene el icono de traslado segun sea el caso (sencillo o redondo)
     * @param {object} item elemento de la tabla
     */
    getIconoTraslado(item) {
      return item.servicios[0].tipo_traslado === 'redondo'
        ? 'mdi-swap-horizontal-circle-outline'
        : 'mdi-swap-horizontal-circle-outline'
    },

    /**
     * Obtiene el icono correspondiente al tipo de vehículo.
     * @param {Object} item - El objeto que contiene los detalles del vehículo.
     * @return {string} El nombre del icono que representa el tipo de vehículo.
     */
    getIconoVehiculo(item) {
      const tipo = item
      switch (tipo) {
        case 'tesla':
          return 'icono-tesla'
        case 'luxe':
          return 'mdi-diamond-stone'
        case 'sedan':
          return 'mdi-car-outline'
        default:
          return 'mdi-diamond-outline'
      }
    },
  },
}
