<template>
  <v-container id="operations" tag="section">
    <base-material-card
      class="px-5 py-3"
      color="primary"
      icon="mdi-atom"
      inline
      :title="$t('operations')"
    >
      <v-spacer></v-spacer>
      <template v-slot:after-heading></template>

      <template v-slot:corner-button>
        <v-layout wrap>
          <!-- Toolbar -->
          <!-- <v-toolbar dense flat>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  color="green"
                  @click="handleSendMultipleTransfers"
                >
                  <v-icon color="primary" size="32">mdi-check-circle-outline</v-icon>
                </v-btn>
              </template>
              <span>Aceptar seleccionados</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  color="error"
                  @click="handleRejectMultipleTransfers"
                >
                  <v-icon color="error" size="32">mdi-close-circle-outline</v-icon>
                </v-btn>
              </template>
              <span>Rechazar seleccionados</span>
            </v-tooltip>
          </v-toolbar> -->
          <!-- Textfield para la barra de Búsqueda -->
          <!-- <v-text-field
              append-icon="mdi-magnify"
              class="ml-auto"
              hide-details
              label="Búsqueda"
              single-line
              style="max-width: 250px"
              v-model="search"
            /> -->
          <!-- Selector de fecha  -->
          <v-menu
            max-width="290px"
            min-width="290px"
            offset-y
            transition="scale-transition"
            v-model="dateMenu"
            :close-on-content-click="false"
            :nudge-right="40"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                dense
                label="Seleccione la fecha"
                prepend-icon="mdi-calendar-month"
                readonly
                v-on="on"
                :value="fecha"
              ></v-text-field>
            </template>
            <v-date-picker
              locale="es-MX"
              no-title
              v-model="fecha"
              :min="minDateDisp('1d')"
              :max="maxDateDisp"
              @change="obtenerTraslados"
              @input="dateMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-layout>
      </template>

      <!-- Overlay para retroalimentar al usuario -->
      <v-overlay :value="overlay">
        <v-progress-circular color="success" indeterminate :size="50"></v-progress-circular>
      </v-overlay>

      <!-- Diálogo para asignar proveedor -->
      <v-dialog max-width="400px" v-model="dialog">
        <v-card class="mx-auto">
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title class="text-h3">
                {{ editedItem.nombre }} {{ editedItem.apellidos }}
              </v-list-item-title>
              <v-list-item-subtitle
                >Pax: {{ editedItem.pasajeros }} <br />
                Tipo vehiculo: {{ editedItem.tipo_vehiculo }}
                <!-- <br />
                Id: {{ editedItem.pago.moneda }} -->
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-divider class="mx-3"></v-divider>

          <v-card-text>
            <v-col cols="12">
              <v-select
                dense
                label="Seleccione el proveedor"
                item-text="transportadora"
                item-value="transportadora"
                v-model="editedItem.asignado.proveedor"
                :items="proveedores"
                @change="onTransportadoraChange"
              ></v-select>
            </v-col>

            <v-col cols="12">
              <v-select
                dense
                label="Seleccione la unidad"
                no-data-text="Seleccione primero un proveedor"
                v-model="editedItem.asignado.unidad"
                :items="unidades"
              ></v-select>
            </v-col>
          </v-card-text>

          <!-- <v-divider class="mx-3"></v-divider> -->
          <!-- Botones y CheckList -->
          <v-card-actions>
            <v-btn color="error" text @click="cancelarProveedor">Cancelar</v-btn>
            <v-btn color="primary" text @click="guardarProveedor">Guardar</v-btn>
          </v-card-actions>
        </v-card>
        <!-- <v-container id="transfersDetails" fluid tag="section">
          <v-row align="center" justify="center">
            <v-col cols="12">
              <base-material-card color="primary">
                <template v-slot:heading>
                  <div class="display-2 font-weight-bold">
                    {{ editedItem.nombre }} {{ editedItem.apellidos }}
                  </div>
                  <div class="subtitle-1 font-weight-medium">
                    <b>Reserva:</b> {{ editedItem._id }}
                  </div>
                  <div class="subtitle-1 font-weight-medium">
                    <b>Telefono:</b> {{ editedItem.celular }}
                  </div>
                </template>
                <v-card-text>
                  <v-container class="pa-0" fluid>
                    <p class="display-2"><b>Detalles del traslado:</b></p>
                    <p><b>Tipo Vehiculo:</b> {{ editedItem.tipo_vehiculo }}</p>
                    <p><b>Pasajeros: </b> {{ editedItem.pasajeros }}</p>
                  </v-container>
                </v-card-text>
              </base-material-card>
            </v-col>
          </v-row>
        </v-container> -->
      </v-dialog>

      <v-divider class="mt-3" />

      <!-- Tabla de datos de Traslados -->
      <v-data-table
        dense
        item-key="_id"
        loading-text="Cargando... Espere por favor"
        show-expand
        show-select
        x-small
        v-model="selectedTransfers"
        :expanded.sync="expanded"
        :headers="headers"
        :items="traslados"
        :loading="isLoading"
        :search.sync="search"
        :single-expand="singleExpand"
      >
        <!-- <template v-slot:top>
          <v-select label="Fecha" style="max-width: 250px"></v-select>
        </template> -->

        <!-- Slot Número -->
        <template v-slot:[`item.numero`]="{ item }">
          <span class="text-md font-weight-light text-body" style="font-size: 0.8rem">
            {{ item.numero }}
          </span>
        </template>

        <!-- Slot Fecha -->
        <template v-slot:[`item.fecha`]="{ item }">
          <span class="text-md font-weight-light text-body" style="font-size: 0.8rem">
            {{ formatDate(item.fecha) }}
          </span>
        </template>

        <!-- Slot Pax -->
        <template v-slot:item.pax="{ item }">
          <span class="text-md font-weight-light text-body" style="font-size: 0.8rem">
            {{ item.pasajeros }}
          </span>
        </template>

        <!-- Slot Pickup -->
        <template v-slot:item.pickup="{ item }">
          <span class="text-md font-weight-light text-body" style="font-size: 0.8rem">
            {{ formatTime(item.pickup) }} <br />
            {{ formatDate(item.pickup) }}
          </span>
        </template>

        <!-- Slot Two Fields One Column -->
        <template v-slot:[`item.vuelo`]="{ item }">
          <!-- <TwoFieldsOneColumn
            title1="Aerolinea"
            :line1="item.aerolinea"
            title2="Vuelo"
            :line2="item.vuelo"
          /> -->
          <span class="text-md font-weight-light text-body" style="font-size: 0.8rem">
            {{ item.aerolinea + ' ' + item.vuelo }}
          </span>
        </template>

        <!-- Slot pasajero -->
        <template v-slot:[`item.nombre`]="{ item }">
          <span class="text-md font-weight-light text-body" style="font-size: 0.8rem">
            {{ item.nombre }}
          </span>
        </template>

        <!-- Slot Conditional Icon -->
        <!-- <template v-slot:[`item.traslado`]="{ item }">
          <ConditionalIcon
            :texto="item.origen"
            cond="Cancún Airport"
            primaryIcon="mdi-airplane-landing"
            secondaryIcon="mdi-airplane-takeoff"
            secondaryColor="blue"
          />
        </template> -->

        <!-- Slot Two Fields One Column -->
        <template v-slot:[`item.origen`]="{ item }">
          <span class="text-md font-weight-light text-body" style="font-size: 0.9rem">
            <TwoFieldsOneColumn
              title1="Origen"
              :line1="item.origen"
              title2="Destino"
              :line2="item.destino"
            />
          </span>
        </template>

        <!-- Slot Conditional Icon Tipo Pago -->
        <!-- <template v-slot:[`item.pago.tipo`]="{ item }">
          <ConditionalIcon
            :texto="item.pago.tipo"
            cond="cash"
            primaryIcon="mdi-cash-multiple"
            secondaryIcon="mdi-credit-card-outline"
            primaryColor="green"
            secondaryColor="blue"
          />
        </template> -->

        <!-- Slot Formater Total -->
        <template v-slot:[`item.total`]="{ item }">
          <span class="text-md font-weight-light text-body" style="font-size: 0.8rem">
            <Formater :number="item.total" :currency="item.pago.moneda" />
          </span>
        </template>

        <!-- Slot Two Fields One Column -->
        <template v-slot:[`item.proveedor`]="{ item }">
          <span class="text-md font-weight-light text-body" style="font-size: 0.9rem">
            <TwoFieldsOneColumn
              title1="Prov"
              :line1="item.asignado ? item.asignado.proveedor : ''"
              title2="Unidad"
              :line2="item.asignado ? item.asignado.unidad : ''"
            />
          </span>
        </template>

        <!-- Slot tamaño de letra -->
        <template v-slot:[`item.estatus`]="{ item }">
          <div class="name-info-title">{{ item.estatus }}</div>
        </template>

        <!-- Botones de acciones -->
        <template v-slot:[`item.actions`]="{ item }">
          <!-- <v-tooltip slot="append" top>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" class="mr-2" small @click="showItem(item)"
                >mdi-file-document-check</v-icon
              >
            </template>
            <span>Asigna proveedor</span>
          </v-tooltip> -->
          <v-icon class="mr-2" small @click="editItem(item)">mdi-file-document-check</v-icon>
          <!-- <v-icon class="mr-2" small @click="sendItem(item)">mdi-send</v-icon> -->
          <!-- <v-icon small @click="deleteItem(item)">mdi-delete</v-icon> -->
        </template>

        <!-- Caracteristicas del traslado -->
        <template v-slot:[`item.caracteristicas`]="{ item }">
          <div
            class="icon-container"
            style="display: flex; flex-direction: row; align-items: flex-start"
          >
            <!-- Origen de la reserva -->
            <ConditionalIcon
              :texto="item.fuente"
              cond="Dashboard"
              margen="mr-1"
              primaryIcon="mdi-view-dashboard"
              secondaryIcon="mdi-web"
              primaryColor="secondary"
              secondaryColor="secondary"
              size="small"
            />
            <!-- Tipo de traslado (llegada-salida-terrestre) -->
            <span class="group pa-0" v-if="item.clave === 'hotel-hotel'">
              <v-icon class="mr-1" small color="green darken-4">mdi-car</v-icon>
            </span>
            <ConditionalIcon
              v-else
              :texto="item.origen"
              cond="Cancún Airport"
              margen="mr-1"
              primaryIcon="mdi-airplane-landing"
              secondaryIcon="mdi-airplane-takeoff"
              secondaryColor="blue"
              size="small"
            />
            <!-- Tipo de vehiculo -->
            <TeslaIcon
              class="mr-1"
              v-if="getIconoVehiculo(item.tipo_vehiculo) === 'icono-tesla'"
              iconWidth="18px"
              iconHeight="18px"
            ></TeslaIcon>
            <v-icon class="mr-1" v-else :color="getColorIconoVehiculo(item.tipo_vehiculo)">{{
              getIconoVehiculo(item.tipo_vehiculo)
            }}</v-icon>
            <!-- Tipo de pago -->
            <!-- <PaypalIcon
              v-if="item.pago.tipo === 'paypal'"
              width="16px"
              height="16px"
              top="'20px'"
              
              style="align-self: flex-start"
            /> -->
            <!-- <span class="group pa-0" v-if="item.pago.tipo === 'paypal'"> -->
            <!-- <v-icon class="mr-1" small color="blue darken-4">mdi-alpha-p-circle</v-icon> -->
            <PaypalIcon
              class="mr-1"
              v-if="item.pago.tipo === 'paypal'"
              width="16px"
              height="16px"
              iconTopMargin="2px"
              style="align-self: flex-start"
            />
            <!-- </span> -->
            <ConditionalIcon
              :texto="item.pago.tipo"
              cond="cash"
              margen="mr-1"
              primaryIcon="mdi-cash-multiple"
              secondaryIcon="mdi-credit-card-outline"
              primaryColor="green"
              secondaryColor="blue"
              size="small"
              v-else
            />
            <!-- Asignado -->
            <ConditionalIcon
              :texto="item.asignado && item.asignado.enviado ? item.asignado.enviado : 'false'"
              cond="true"
              primaryIcon="mdi-send-check-outline"
              secondaryIcon="mdi-send-outline"
              primaryColor="warning"
              size="small"
            />
            <!-- <v-icon small @click="deleteItem(item)">mdi-delete</v-icon> -->
          </div>
        </template>

        <!-- Proveedor del traslado -->
        <!-- <template v-slot:item.proveedor="props">
          <span class="text-md font-weight-light text-body" style="font-size: 0.8rem">
            <v-edit-dialog
              :return-value.sync="props.item.proveedor"
              cancel-text="Cancelar"
              save-text="Ok"
              transition="expand-transition"
              large
              @save="guardarProveedor"
              @cancel="cancelarProveedor"
            >
              {{ props.item.proveedor }}
              <template v-slot:input>
                <v-select v-model="props.item.proveedor" :items="proveedores2"></v-select>
              </template>
            </v-edit-dialog>
          </span>
        </template> -->

        <!-- Empieza expanded item -->
        <template v-slot:[`expanded-item`]="{ headers, item }">
          <!-- Amenidades -->
          <td
            :colspan="headers.length / 4"
            style="font-size: 83%; padding: 5px 5px 5px 20px; vertical-align: top"
          >
            <h5>AMENIDADES:</h5>
            <!-- <br /> -->
            <div v-for="(amenidad, index) in item.amenidades" :key="index">
              <i> {{ amenidad.cantidad }} </i> <i>{{ amenidad.producto }} </i>
              <i> {{ amenidad.descripcion }} </i>
              <br />
            </div>
          </td>

          <!-- Extras -->
          <td
            :colspan="headers.length / 4"
            style="font-size: 83%; padding: 5px 5px 5px 20px; vertical-align: top"
          >
            <h5>EXTRAS:</h5>
            <div v-for="(extra, index) in item.extras" :key="index">
              <i> {{ extra.cantidad }} </i> <i>{{ extra.extra }} </i>
              <i> {{ extra.descripcion }} </i>
            </div>
          </td>

          <td
            :colspan="headers.length / 3"
            style="font-size: 83%; padding: 5px 5px 5px 20px; vertical-align: top"
          >
            <h5>NOTAS:</h5>

            <i> {{ item.nota }} </i>
          </td>
        </template>
        <!-- Termina expanded item -->
      </v-data-table>
      <v-divider class="mt-3" />

      <!-- DataExporter -->
      <DataExporter
        :dataArray="traslados"
        :dataFields="fields"
        fileName="Traslados"
        :formatRules="formatRules"
        :title="pdfTitle"
      />
    </base-material-card>
    <!-- Botones de prueba -->
    <div class="text-center pt-2">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            fab
            small
            @click="handleSendMultipleTransfers"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon> mdi-send-clock-outline </v-icon>
          </v-btn>
        </template>
        <span>Enviar seleccionados</span>
      </v-tooltip>
    </div>
    <!-- Termina botones de prueba -->
  </v-container>
</template>

<script>
import moment from 'moment'
import ConditionalIcon from '@/components/general/ConditionalIcon.vue'
import DataExporter from '@/components/general/DataExporter.vue'
// import DateTime from '@/components/general/DateTime.vue'
import Formater from '@/components/general/Formater.vue'
import PaypalIcon from '@/components/icons/PaypaIcon.vue'
import TeslaIcon from '@/components/icons/TeslaIcon.vue'
import TwoFieldsOneColumn from '@/components/general/TwoFieldsOneColumn.vue'
// import PaypalIcon from '@/components/icons/PaypaIcon.vue'
// import ClientInfo from '@/components/general/ClientInfo.vue'
import dateTimeMixin from '@/mixins/dateTimeMixin'
import iconosTrasladoMixin from '@/mixins/iconosTrasladoMixin'

export default {
  name: 'Operaciones',
  mixins: [dateTimeMixin, iconosTrasladoMixin],
  components: {
    ConditionalIcon,
    DataExporter,
    // DateTime,
    Formater,
    TeslaIcon,
    PaypalIcon,
    TwoFieldsOneColumn,
    // ClientInfo,
  },
  data: () => ({
    dateMenu: false,
    dialog: false,
    editedIndex: -1,
    fecha: '',
    isLoading: false,
    overlay: false,
    search: undefined,
    singleExpand: true,
    expanded: [],
    headers: [
      // { text: '', value: 'traslado', align: 'center', sortable: false, width: '1%' },
      // { text: '', value: 'tipo_vehiculo', align: 'center', sortable: false, width: '1%' },
      // { text: '', value: 'pago.tipo', align: 'center', sortable: false, width: '1%' },
      { text: 'Nº', value: 'numero', visible: false, align: 'start', width: '7%' },
      { text: 'CARACTERISTICAS', align: 'center', sortable: false, value: 'caracteristicas' },
      { text: 'PAX', value: 'pax', align: 'center', sortable: false },
      { text: 'PICKUP', value: 'pickup', align: 'center', sortable: false },
      { text: 'VUELO', value: 'vuelo', align: 'start', sortable: false },
      { text: 'NOMBRE', value: 'nombre', align: 'start' },
      { text: 'RUTA', value: 'origen', align: 'start', sortable: false },
      { text: 'BALANCE', value: 'total', align: 'start', sortable: false },
      { text: 'PROVEEDOR', value: 'proveedor', align: 'start', sortable: false },
      // { text: 'UNIDAD', value: 'asignado.unidad', align: 'center', sortable: false },
      { text: 'ACCIONES', value: 'actions', align: 'center', sortable: false },

      { text: '', value: 'data-table-expand' },
    ],
    proveedores: [
      { value: 1, text: 'EOS Travel' },
      { value: 2, text: 'Luxlimo' },
    ],
    proveedores2: ['EOS Travel', 'Luxlimo', 'Legacy'],
    selectedTransfers: [],
    traslados: [],
    unidades: [],
    editedItem: {
      _id: '',
      nombre: '',
      apellidos: '',
      correo: '',
      celular: '',
      tipo_traslado: '',
      tipo_vehiculo: '',
      clave: '',
      pasajeros: 0,
      precio: 0,
      traslados: {},
      nota: '',
      asignado: {
        proveedor: '',
        unidad: '',
      },
      fuente: '',
    },
    fields: {
      Nº: 'numero',
      Recibo: '_id',
      Nombre: 'nombre',
      Pax: 'pasajeros',
      Pickup: 'pickup',
      Aerolinea: 'aerolinea',
      Vuelo: 'vuelo',
      Vehiculo: 'tipo_vehiculo',
      Origen: 'origen',
      Destino: 'destino',
      Balance: 'total',
      Moneda: 'pago.moneda',
      Proveedor: 'asignado.proveedor',
      Unidad: 'asignado.unidad',
    },
    formatRules: {
      Balance: { format: (value) => parseFloat(value).toFixed(2), align: 'right' },
      Pickup: {
        format: (value) => moment(value).tz('America/Cancun').format('HH:mm'),
        align: 'center',
      },
    },
  }),
  created() {
    // metodo
    this.fecha = this.obtenerFechaManana()
    this.obtenerTransportadoras()
    this.obtenerTraslados()
  },
  computed: {
    formTitle() {
      return 'Reserva: ' + this.editedItem._id
    },

    computedDate() {
      return moment(this.editedItem.traslados.fecha).format('DD-MM-YYYY')
    },

    computedTime() {
      return moment(this.editedItem.traslados.hora, ['h:mm A']).format('HH:MM')
    },

    pdfTitle() {
      const fechaTraslados = moment(this.fecha).locale('es').format('LL')
      return `Relación de traslados del día ${fechaTraslados}`
    },
  },
  methods: {
    /**
     * @description Cierra el dialogo para asignar proveedores sin asignar proveedor
     * ni unidad
     */
    cancelarProveedor() {
      this.close()
      this.handleAlert('error', 'No se asigno el proveedor')
    },

    /**
     * @description Cierra el diálogo de mantenimiento de generos
     * @param {bool} checkbox boleano que indica el estado del checkbox
     */
    close() {
      this.dialog = false
    },

    /**
     * Crea un array con los elementos validos de traslados presentes en selectedTransfers.
     * @returns {Array} Retorna un array con los elementos validados.
     */
    createValidatedTransfersArray() {
      const validatedTransfers = []

      for (const transfer of this.selectedTransfers) {
        const traslado = this.traslados.find((t) => t._id === transfer._id)

        if (traslado) {
          // Clonamos las amenidades obteniendo los campos que queremos
          const amenidades =
            traslado.amenidades?.map(({ producto, descripcion, cantidad }) => ({
              producto,
              descripcion,
              cantidad,
            })) || []

          // Clonamos los extras obteniendo los campos que queremos
          const extras =
            traslado.extras?.map(({ extra, descripcion, cantidad }) => ({
              extra,
              descripcion,
              cantidad,
            })) || []

          const updatedTraslado = {
            aerolinea: traslado.aerolinea,
            clave: traslado.clave,
            destino: traslado.destino,
            fecha: traslado.fecha,
            fuente: traslado.fuente,
            hora: traslado.hora,
            nombre: traslado.nombre,
            nota: traslado.nota,
            numero: traslado.numero,
            origen: traslado.origen,
            pasajeros: traslado.pasajeros,
            pickup: traslado.pickup,
            unidad: traslado.asignado.unidad,
            vuelo: traslado.vuelo,
            reserva_id: traslado._id,
            balance: traslado.total,
            moneda: traslado.pago.moneda,
            estatus: 'Programado',
            amenidades: amenidades,
            extras: extras,
          }
          validatedTransfers.push(updatedTraslado)
        }
      }

      return validatedTransfers
    },

    /* deleteItem(item) {
      console.info(`Borrando ${item}`)
    }, */

    /**
     * @description prepara un registro para actualizar los datos del proveedor
     * y muestra el dialogo
     */
    editItem(item) {
      if (!item.asignado) {
        item.asignado = { proveedor: '', unidad: '' }
      }
      this.isEditing = true
      this.editedIndex = this.traslados.indexOf(item)
      this.editedItem = Object.assign({}, item)
      if (this.editedItem.asignado.enviado) {
        // TODO: validar que se hara si ha sido asignado
        console.log('Ya se envio el traslado')
      }
      this.dialog = true
    },

    /**
     * Este método guarda la información del proveedor asignado a un traslado.
     * Después de preparar la información necesaria, se realiza una petición a la API.
     * @returns {Promise} - Una promesa que se resuelve cuando se completan todas las operaciones.
     */
    async guardarProveedor() {
      const payload = {
        id: this.editedItem._id,
        body: {
          proveedor: this.editedItem.asignado.proveedor,
          unidad: this.editedItem.asignado.unidad,
          origen: this.editedItem.origen,
          destino: this.editedItem.destino,
        },
      }

      try {
        const response = await this.$store.dispatch('operation/assignProvider', payload)
        this.handleAlert('primary', response.data.message)
      } catch (error) {
        this.overlay = false
        this.handleError(error)
      } finally {
        this.close()
      }
    },

    /**
     * Este método crea y muestra una alerta con un color y un texto determinados.
     * @param {string} color - El color de la alerta.
     * @param {string} text - El texto de la alerta.
     */
    handleAlert(color, text) {
      this.$store.commit('ALERT', {
        color: color,
        text: text,
      })
    },

    /**
     * Este método maneja los errores producidos durante el envío de los traslados.
     * Muestra una alerta con un mensaje de error apropiado en función del tipo de error.
     * @param {Object} error - El objeto de error.
     */
    handleError(error) {
      if (error.message === 'wrong data') {
        this.handleAlert(
          'error',
          'El proveedor no es el mismo para todos los traslados seleccionados'
        )
      } else if (error.message === 'missing data') {
        this.handleAlert(
          'error',
          `No se han asignado proveedor y unidad a la reserva ${error.numero}`
        )
      } else if (error.message === 'no selection') {
        this.handleAlert('error', `No ha seleccionado ningún traslado`)
      } else if (error.message === 'invalid provider API') {
        this.handleAlert(
          'error',
          `El proveedor seleccionado no tiene definida una API, no se pueden enviar los traslados`
        )
      } else {
        this.handleAlert('error', 'Ha ocurrido un error inesperado')
      }
    },

    /**
     * Este método maneja el envío de múltiples transferencias.
     * Primero valida las transferencias seleccionadas y luego las envía a la API.
     * @returns {Promise} - Una promesa que se resuelve cuando se completan todas las operaciones.
     */
    async handleSendMultipleTransfers() {
      this.overlay = true
      try {
        const { apiUrl, writeApiKey } = await this.validateSelectedTransfers()
        const validatedTransfers = this.createValidatedTransfersArray()

        let payload = {
          variables: {
            TransfersInfo: {
              transfers: validatedTransfers,
            },
          },
          apiUrl,
          writeApiKey,
        }

        try {
          const response = await this.$store.dispatch(
            'transportadora/sendMultipleTransfersToProvider',
            payload
          )
          this.handleSendMultipleTransfersResponse(response)
        } catch (err) {
          this.overlay = false
          console.log(err)
          this.handleAlert('error', 'Ha ocurrido un error al asignar los traslados al proveedor')
        }
      } catch (error) {
        this.overlay = false
        this.handleError(error)
      }
    },

    /**
     * Este método maneja la respuesta de la API tras el envío de múltiples transferencias.
     * Si hay errores en la respuesta, muestra una alerta con el mensaje de error.
     * Si no hay errores, actualiza el estado de las transferencias enviadas.
     * @param {Object} response - La respuesta de la API tras el envío de las transferencias.
     * @returns {Promise} - Una promesa que se resuelve cuando se completan todas las operaciones.
     */
    async handleSendMultipleTransfersResponse(response) {
      if (response.data.errors) {
        const message =
          response.data.errors[0].extensions.response.statusCode === 403
            ? 'Agencia no autorizada para enviar traslados'
            : response.data.errors[0].extensions.response.message
        this.overlay = false
        this.handleAlert('warning', message)
        return
      }

      const acceptedTransfers = {
        updates: response.data.data.createMultipleTransfers.map((update) => ({
          ...update,
          enviado: true,
        })),
      }

      try {
        acceptedTransfers.updates.forEach((update) => {
          let transferToUpdate = this.traslados.find(
            (traslado) => traslado._id === update.reserva_id
          )
          if (transferToUpdate) {
            transferToUpdate.asignado.enviado = true
          }
        })
        await this.$store.dispatch('operation/updateInfo', acceptedTransfers)
        this.overlay = false
        this.selectedTransfers = []
        this.handleAlert('primary', 'Los traslados se asignaron al proveedor con exito')
      } catch (error) {
        this.overlay = false
        this.handleAlert(
          'warning',
          'Los traslados se asignaron al proveedor pero no se actualizo la info en la base de datos local'
        )
      }
    },

    /**
     * Este método calcula la fecha del día siguiente a la fecha actual.
     * La fecha se calcula sumando 24 horas (en milisegundos) a la fecha actual.
     * Luego se formatea en el formato 'YYYY-MM-DD' y se devuelve como una cadena.
     * @returns {string} - La fecha del día siguiente en el formato 'YYYY-MM-DD'.
     */
    obtenerFechaManana() {
      const fechaManana = new Date(Date.now() + 24 * 60 * 60 * 1000)
      return `${fechaManana.getFullYear()}-${String(fechaManana.getMonth() + 1).padStart(
        2,
        '0'
      )}-${String(fechaManana.getDate()).padStart(2, '0')}`
    },

    /**
     * Obtiene las transportadoras disponibles a partir de la acción 'getCarriers' del store.
     * Almacena la respuesta en la propiedad 'proveedores' del componente.
     * En caso de error, muestra una alerta con el mensaje de error y detiene la carga.
     *
     * @returns {Promise<void>} - Retorna una promesa que se resuelve cuando la operación termina.
     */
    obtenerTransportadoras() {
      let payload = {}
      this.$store
        .dispatch('transportadora/getCarriers', payload)
        .then((response) => {
          this.proveedores = response.data.map(
            ({ transportadora, flotilla, api_url, write_api_key }) => ({
              transportadora,
              flotilla,
              api_url,
              write_api_key,
            })
          )
        })
        .catch((err) => {
          this.isLoading = false
          this.$store.commit('ALERT', {
            color: 'error',
            text: err.response.data.error,
            // Enviar a inicio de sesión
          })
        })
    },

    /**
     * Obtiene los traslados disponibles a partir de una fecha con la acción 'getTransfersOperation' del store.
     * La operación se realiza bajo ciertos parámetros que se envían en el payload,
     * entre ellos, la fecha de los traslados que se desean obtener.
     * Almacena la respuesta en la propiedad 'traslados' del componente y numera los traslados.
     * En caso de error, muestra una alerta con el mensaje de error y detiene la carga.
     *
     * @returns {Promise<void>} - Retorna una promesa que se resuelve cuando la operación termina.
     */
    obtenerTraslados() {
      this.isLoading = true
      this.overlay = true
      let payload = {
        page: 1,
        pageSize: 200,
        query: {
          fecha: this.fecha,
        },
        sort: {
          pickup: 'asc',
        },
      }

      this.$store
        .dispatch('operation/getTransfersOperation', payload)
        .then((response) => {
          let respuesteishon = response.data.results
          respuesteishon.forEach((element, index) => {
            element.numero = index + 1
          })
          this.traslados = respuesteishon
          this.isLoading = false
          this.overlay = false
          if (response.data.results.length <= 0) {
            this.$store.commit('ALERT', {
              color: 'info',
              text: 'No hay traslados para esa fecha',
            })
          }
        })
        .catch((err) => {
          this.isLoading = false
          this.overlay = false
          console.log(err)
          this.$store.commit('ALERT', {
            color: 'error',
            text: 'Ocurrio un error al tratar de obtener traslados',
            // Enviar a inicio de sesión
          })
        })
    },

    onTransportadoraChange(item) {
      this.unidades = []
      console.log(` ${item}`)
      const selectedProvider = this.proveedores.find(
        ({ transportadora }) => transportadora === item
      )
      if (selectedProvider) {
        this.unidades = selectedProvider.flotilla.map(({ unidad }) => unidad)
      }
    },

    /**
     * @description prepara un registro para enviar al proveedor
     */
    sendItem(item) {
      if (!item.asignado) {
        console.log('no tiene asignado proveedor')
        item.asignado = { proveedor: '', unidad: '' }
      }
      // console.log(item)
      let payload = {
        transferInfo: {
          numero: item.numero,
          nombre: item.nombre,
          origen: item.origen,
          destino: item.destino,
          fecha: item.fecha,
          hora: item.hora,
          pickup: item.pickup,
          pasajeros: item.pasajeros,
          aerolinea: item.aerolinea,
          vuelo: item.vuelo,
          reserva_id: item._id,
          balance: item.total,
          estatus: 'Programado',
        },
      }
      console.log(payload)
      this.$store
        .dispatch('transportadora/sendTransferToProvider', payload)
        .then((response) => {
          this.$store.commit('ALERT', {
            color: 'primary',
            text: 'El traslado se asigno al proveedor con exito',
          })
          console.log(response)
        })
        .catch((err) => {
          console.log(err)
        })
    },

    /* showItem(item) {
      this.editedIndex = this.traslados.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
      console.info(`Editando ${item}`)
    }, */

    /**
     * Este método valida los traslados seleccionados. Comprueba si el array de traslados seleccionados está vacío,
     * si todos los traslados seleccionados tienen un proveedor y una unidad asignados,
     * si todos los traslados seleccionados tienen el mismo proveedor y si este proveedor tiene una API válida.
     * @throws {Error} Si el array de traslados seleccionados está vacío, si algún traslado seleccionado no tiene
     * un proveedor y una unidad asignados, si los traslados seleccionados no tienen el mismo proveedor,
     * o si el proveedor no tiene una API válida.
     * @returns {Object} - Devuelve un objeto con los campos `api_url` y `write_api_key` si todas las validaciones pasan.
     */
    validateSelectedTransfers() {
      // Validar si el array selectedTransfers está vacío
      if (!this.selectedTransfers.length) {
        throw new Error('no selection')
      }

      let providerName

      for (const transfer of this.selectedTransfers) {
        const traslado = this.traslados.find((t) => t._id === transfer._id)

        // Valída si estan asignados el proveedor y la unidad
        if (
          !traslado ||
          !traslado.asignado ||
          !traslado.asignado.proveedor ||
          !traslado.asignado.unidad
        ) {
          // throw new Error('missing data', { numero: traslado.numero })
          const error = new Error('missing data')
          error.numero = traslado.numero
          throw error
        }

        // Valida si todos los traslados están asignados al mismo proveedor
        if (!providerName) {
          providerName = traslado.asignado.proveedor
        } else if (traslado.asignado.proveedor !== providerName) {
          throw new Error('wrong data')
        }
      }

      // Validar si el proveedor tiene una API válida
      const provider = this.proveedores.find((p) => p.transportadora === providerName)

      if (!provider || !provider.api_url || !provider.write_api_key) {
        throw new Error('invalid provider API')
      }

      return {
        apiUrl: provider.api_url,
        writeApiKey: provider.write_api_key,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.name-info-title {
  font-size: 0.9rem;

  .tim-note {
    bottom: 10px;
    color: #c0c1c2;
    display: block;
    font-weight: 400;
    font-size: 13px;
    line-height: 13px;
    left: 0;
    margin-left: 20px;
    width: 260px;
  }
}
</style>
