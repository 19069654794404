import moment from 'moment'
import 'moment-timezone'

export default {
  computed: {
    /**
     * @description Calcula la fecha máxima para poder realizar una reserva
     * en este caso en particular un año posterior a la fecha de la reserva
     *
     * @return {date} fecha un año posterior a la fecha actual
     */
    maxDateDisp() {
      const date = new Date() //.toISOString().substr(0, 10)
      const year = date.getFullYear() + 1
      const month = date.getMonth() + 1
      const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
      console.debug(year + '-' + (month < 10 ? '0' + month : month) + '-' + day)
      return year + '-' + (month < 10 ? '0' + month : month) + '-' + day
    },

    // minDateDisp() {
    //   console.log('time')
    //   let date = new Date('2022-09-08')
    //   return this.formatDate(date)
    // },
  },
  methods: {
    /**
     * Devuelve una fecha en formato ISO 8601 con la zona horaria especificada
     *
     * @param {string} fecha - La fecha en formato 'YYYY-MM-DD'.
     * @param {string} hora - La hora en formato 'HH:mm'.
     * @param {string} zona - La zona horaria en formato de IANA.
     *
     * @returns {string} - La fecha y hora especificadas en el formato ISO 8601 con la zona horaria especificada.
     *
     * @example
     * // returns '2023-05-26T22:30:00-05:00'
     * formatDateZone('2023-05-26', '22:30', 'America/Cancun');
     */
    formatDateZone(fecha, hora, zona) {
      const fechaHora = `${fecha}T${hora}`
      return moment.tz(fechaHora, zona).format()
    },

    calculaPickup(fecha, hora, zona, pickup) {
      const fechaHora = `${fecha}T${hora}`
      // return moment.tz(fechaHora, zona).format()
      return moment.tz(fechaHora, zona).subtract(pickup, 'hours').format()
    },

    formatDate(date) {
      return moment(date).format('MMM-DD')
    },

    formatTime(time) {
      // return moment.utc(time).format('HH:mm')
      return moment.tz(time, 'America/Cancun').format('HH:mm')
    },

    minDateDisp(time) {
      // console.log('entro en min date disp')
      // console.log(time)
      let date = new Date()
      const unit = time.slice(-1)
      const value = time.slice(0, time.length - 1)
      let year = date.getFullYear()
      let month = date.getMonth() + 1
      let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()

      switch (unit) {
        case 'y':
          year = year - value
          break
        case 'm':
          month = month - value
          break
        default:
          day = day - value
          break
      }

      // let date = new Date('2022-09-08')
      date = year + '-' + (month < 10 ? '0' + month : month) + '-' + day
      return moment(date).format('YYYY-MM-DD')
      // return this.formatDate(date)
    },
  },
}
